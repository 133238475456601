/**
 * Navbar menu button toggle.
 * Displays navbar menu list.
 * Modified from https://www.aleksandrhovhannisyan.com/blog/responsive-navbar-without-bootstrap/
 * https://codepen.io/AleksandrHovhannisyan/pen/xxwWama
 */
/* The header element has the #navbar id*/
const navbar = document.getElementById("navbar");
/* The button element has the .navbar-toggle class */
const navbarToggle = navbar.querySelector(".navbar-toggle");

/* 1. Open the navar on mobile by adding the .is-active class to the element with id #navbar.
  - The .is-active class defines the menu as a flex column.

2. Set aria-label to 'Close navigation menu' for the button with the .navbar-toggle class
  - When the toggle button is open, screen readers will see the text "Close navigation menu"*/
function openMobileNavbar() {
  navbar.classList.add("is-active");
  navbarToggle.setAttribute("aria-label", "Close navigation menu.");
}

/* Close the open menu by removing the .is-active class
   - Switch the aria label to "Open navigation menu" */
function closeMobileNavbar() {
  navbar.classList.remove("is-active");
  navbarToggle.setAttribute("aria-label", "Open navigation menu.");
}

/* When clicking the toggle button if the class is-active is present, run the closeMobileNavbar function, otherwise run the openMobileNavbar function */
navbarToggle.addEventListener("click", () => {
  if (navbar.classList.contains("is-active")) {
    closeMobileNavbar();
  } else {
    openMobileNavbar();
  }
});

/* After the toggle button, a div with the class .navbar-menu, wraps the unordered list. The unordered list class is .navbar-links and list items have the .navbar-link class */
/* Select the wrapper div */
const navbarMenu = navbar.querySelector(".navbar-menu");
/* Select the ul element */
const navbarLinksContainer = navbar.querySelector(".navbar-links");

/* Switch of further propagation of existing events. c.f. a reset */
navbarLinksContainer.addEventListener("click", (clickEvent) => {
  clickEvent.stopPropagation();
});

/* Now the navbar menu can be closed with a click event */
navbarMenu.addEventListener("click", closeMobileNavbar);

/* Close button when click item link */
var navbarLink = document.querySelectorAll(".navbar-link");

navbarLink.forEach(n => n.addEventListener("click", closeMobileNavbar));

/*
const navbar = document.getElementById("menu-toggle");
const navbarToggle = navbar.querySelector(".navbar-toggle");

function openMobileMenu() {
  navbar.classList.add("is-active");
  navbarToggle.setAttribute("aria-label", "Close navigation menu.");
}

function closeMobileMenu() {
  navbar.classList.remove("is-active");
  navbarToggle.setAttribute("aria-label", "Open navigation menu.");
}

navbarToggle.addEventListener("click", () => {
  if (navbar.classList.contains("is-active")) {
    closeMobileMenu();
  } else {
    openMobileMenu();
  }
});

const navbarMenu = navbar.querySelector(".navbar-menu");
const navbarLinksContainer = navbar.querySelector(".navbar-links");

navbarLinksContainer.addEventListener("click", (clickEvent) => {
  clickEvent.stopPropagation();
});

navbarMenu.addEventListener("click", closeMobileMenu);
*/
